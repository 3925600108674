import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { mq } from "../utils/presets"
import Members from "../components/team/members"
import TopImage from "../components/TopImage"

const Title = styled.h1``

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
`

const Textwrapper = styled.div`
  flex: 1 0 auto;
  width: 100%;
  h1 {
    padding: 1rem 1rem 0;
  }
  ${mq.tablet} {
    padding: 0;
    width: 35%;
    margin-right: 1rem;
    h1 {
      padding: 1rem;
      text-align: left;
      border-bottom: none;
      margin-bottom: 0;
      margin-right: 1rem;
      padding-bottom: 0;
    }
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 0;
  }
`

const TeamPageTemplate = ({ data }) => {
  const page = data.page
  const featuredImage = page?.featuredImage

  const team = page?.team

  const seo = page?.seo
  const seoTitle = page?.seo?.opengraphTitle || page.title || ``
  const metaDesc = seo.metaDesc || ``
  const seoImage = page?.featuredImage?.node.localFile.childImageSharp.resize

  return (
    <Layout isFrontPage={page.isFrontPage}>
      <Seo
        title={page.title}
        seoTitle={seoTitle}
        description={metaDesc}
        image={seoImage}
        uri={page.uri}
      />{" "}
      {!!featuredImage && <TopImage featuredImage={featuredImage} />}
      <Wrapper>
        <Textwrapper>
          <Title>{page.title}</Title>

          <Members team={team} />

          <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </Textwrapper>
      </Wrapper>
    </Layout>
  )
}
export default TeamPageTemplate

export const query = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      isFrontPage
      uri
      team {
        members {
          ... on WpPage_Team_Members_Mitarbeiter {
            email
            name
            title
            phone
          }
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        schema {
          articleType
          pageType
          raw
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              resize(width: 1200, height: 627) {
                src
                tracedSVG
                width
                height
                aspectRatio
                originalName
              }
              gatsbyImageData(
                width: 2000
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
