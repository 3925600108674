import React from "react"
import styled from "styled-components"
import { mq, colors } from "../../utils/presets"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope } from "@fortawesome/pro-light-svg-icons"

const ListItem = styled.li`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  background: ${colors.lightgrey};
  padding: 1rem 0.5rem;
  margin: 0;
  border-bottom: 1px solid grey;
  ${mq.tablet} {
    width: 50%;
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    margin: 0;
    &:first-child {
      border-top: none;
    }
  }
  ${mq.xl} {
    display: flex;
    max-width: 330px;
    background: none;
    border: none !important;
  }
`

// const ImageWrapper = styled(Img)`
//   border-radius: 50%;
//   height: 50px;
//   width: 50px;
//   height: 50px;
//   ${mq.tablet} {
//     align-self: center;
//     flex: 0 1 100px;
//     height: 100px;
//     width: 100px;
//     height: 100px;
//   }
//   ${mq.xl} {
//     align-self: center;
//     flex: 0 1 100px;
//   }
// `

const TextWrapper = styled.div`
  margin-left: 0.8rem;
  flex: 1 0 auto;
  width: 30%;
  font-size: 0.8rem;
  .faxlicon {
    display: none;
  }
  ${mq.xl} {
    align-self: flex-start;
    font-size: 1rem;
    .faxlicon {
      display: inline-block;
    }
  }
  a {
    text-decoration: none;
  }
  h2 {
    margin-bottom: 0.1rem;
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: bold;
  }
  p {
    margin: 0;
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
`

const IconWrapper = styled.div`
  flex: 0 1 auto;
  text-align: right;
  .faicon {
    transition: all 0.3s;
    background: white;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    padding: 0.4rem;
    margin-left: 0.1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }
  ${mq.mobilemed} {
    .faicon {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-left: 0.4rem;
      margin-right: 0.7rem;
      padding: 0.5rem;
    }
  }
  ${mq.xl} {
    .faicon {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      padding: 0.4rem;
    }
  }
  ${mq.xl} {
    display: none;
  }
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0;
  border-top: 1px solid grey;
  ${mq.xl} {
    border: none;
  }
`

const Members = ({ team }) => (
  <List>
    {team.members.map((item, i) => (
      <ListItem key={i}>
        {/* <ImageWrapper
              className="memberimage"
              objectFit="cover"
              objectPosition="50% 50%"
              sizes={item.image.localFile.childImageSharp.sizes}
              alt={item.title}
            /> */}
        <TextWrapper>
          <h2>{item.name}</h2>
          <p>{item.title}</p>
          <p style={{ marginTop: ".4rem" }}>
            <a href={"tel:" + item.phone}>
              <FontAwesomeIcon className="faxlicon" icon={faPhone} />{" "}
              {item.phone}
            </a>
          </p>
          <p style={{ marginTop: ".2rem" }}>
            <a href={"mailto:" + item.email}>
              <FontAwesomeIcon className="faxlicon" icon={faEnvelope} />{" "}
              {item.email}
            </a>
          </p>
        </TextWrapper>
        <IconWrapper>
          <a href={"tel:" + item.phone}>
            <FontAwesomeIcon className="faicon" icon={faPhone} size="lg" />
          </a>
          <a href={"mailto:" + item.email}>
            <FontAwesomeIcon className="faicon" icon={faEnvelope} size="lg" />
          </a>
        </IconWrapper>
      </ListItem>
    ))}
  </List>
)

export default Members
